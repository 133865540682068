<template>
	<div :id="mainNavIsHidden ? 'main-nav-hidden' : 'buy-ins'">
		<h1>{{ languageStrings.webPayCashIn }}</h1>
		<div v-if="countryCode !== 'MX' && casinoList.length > 1">
			<h2>{{ languageStrings.selectCasino }}</h2>
			<DropdownMenu :casinoList="casinoList" :languageStrings="languageStrings" />
		</div>
		<BuyIns
			v-if="availableOffers?.length > 0"
			:playerState="playerState"
			:isMobile="isMobile"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
			:availableOffers="availableOffers"
			:systemSettings="systemSettings"
			:webPayOptions="webPayOptions"
			:inGameCurrencyInfo="inGameCurrencyInfo"
			:casinoList="casinoList"
		/>
		<div id="webpay-container" :class="nuveiShow ? 'show' : ''">
			<button class="btn nuvei-cancel-button" @click="cancelNuvei()">
				{{ languageStrings.cancelTransaction }}
			</button>
			<div id="webpay-checkout-element"></div>
		</div>
		<div id="conekta-checkout-container" :class="conektaShow ? 'show' : ''">
			<button class="btn" @click="closeConekta()">
				{{ conektaSuccess ? "Close" : languageStrings.cancelTransaction }}
			</button>
			<div id="conekta-checkout-element"></div>
		</div>
		<dialog id="nuvei-dialog" @click="closeNuveiDialog()">
			<div>
				<h2>{{ languageStrings.transactionResult }}</h2>
				<p v-if="rabbitsfootMessage?.status !== 'RefusedByProvider' && rabbitsfootMessage?.result !== 'ERROR'">
					{{ languageStrings.creditCardPurchase }}:
					{{
						systemCurrencyTool?.formatCurrency(
							nuveiResult?.fromPlayerCashCOC,
							systemCurrencyTool.displayType.minorOrFull
						)
					}}
				</p>
				<div v-else>
					<p>{{ languageStrings.refusedByProvider }}</p>
					<p v-if="rabbitsfootMessage?.statusAdditionalInfo">
						{{ languageStrings.additionalInfo }}: {{ rabbitsfootMessage.statusAdditionalInfo }}
					</p>
				</div>
				<div id="btn-container">
					<router-link class="btn" :title="languageStrings.viewBalances" to="/">{{
						languageStrings.viewBalances
					}}</router-link>
				</div>
			</div>
		</dialog>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import router from "@/router";
import CurrencyTool from "@/dependencies/currencyTool";
import sharedScripts from "@/dependencies/sharedScripts";
import DropdownMenu from "@/components/DropdownMenu.vue";
import BuyIns from "@/components/BuyIns.vue";

export default {
	name: "WebPayCashIn",
	inheritAttrs: false,
	props: {
		casinoList: Array,
		playerState: Object,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		mainNavIsHidden: Boolean,
	},
	components: {
		DropdownMenu,
		BuyIns,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			availableOffers: [],
			webPayOptions: {},
			cashOutCurrency: {},
			inGameCurrencyInfo: {},
			nuveiResult: {},
			nuveiDialog: null,
			selectedCasino: null,
			casinoId: null,
			nuveiShow: false,
			conektaShow: false,
			rabbitsfootMessage: null,
			conektaFrame: "",
			conektaSuccess: false,
			conecktaResult: {},
		};
	},
	watch: {
		casinoId() {
			if (this.countryCode === "MX" && this.casinoId) this.getWebPayOptions();
		},
	},
	created() {
		this.rabbitsfootMessage = null;
		this.eventBus.on("casinoSelected", (payload) => {
			if (typeof payload === "undefined") return;
			this.selectedCasino = payload;
			this.casinoId = payload.id;
			this.inGameCurrencyInfo = new CurrencyTool(payload.inGameCurrencyInfo, this.localeString);
			if (this.casinoId) this.getWebPayOptions();
		});
		this.eventBus.on("showNuveiWidgit", () => {
			this.nuveiShow = true;
		});
		this.eventBus.on("showConektaWidgit", (bool) => {
			this.conektaShow = bool;
		});
		this.eventBus.on("handelNuveiError", (payload) => {
			this.rabbitsfootMessage = payload;
			this.nuveiShow = false;
			this.showNuveiDialog();
		});
		this.eventBus.on("nuveiCheckoutResult", (payload) => {
			this.nuveiResult = payload;
			this.nuveiShow = false;
			this.showNuveiDialog();
		});
		this.eventBus.on("conektaCheckoutResult", (payload) => {
			this.conektaSuccess = true;
			this.conecktaResult = payload;
			// this.conektaShow = false;
			// router.push("/");
		});
		onBeforeUnmount(() => {
			document.getElementById("webpay-checkout-element").innerHTML = "";
			this.eventBus.off("casinoSelected");
			this.eventBus.off("showNuveiWidgit");
			this.eventBus.off("showConektaWidgit");
			this.eventBus.off("handelNuveiError");
			this.eventBus.off("nuveiCheckoutResult");
			this.eventBus.off("conektaCheckoutResult");
		});
	},
	mounted() {
		if (this.countryCode === "MX" || this.casinoList.length === 1)
			this.eventBus.emit("casinoSelected", this.pickDefaultCasino());
		this.nuveiDialog = document.getElementById("nuvei-dialog");
	},
	methods: {
		pickDefaultCasino() {
			let casinoIdSelected;
			casinoIdSelected = this.casinoList.filter((casino) => casino.isEnabled === true)[0];
			return casinoIdSelected;
		},
		cancelNuvei() {
			checkout.destroy();
			this.nuveiShow = false;
		},
		closeConekta() {
			this.conektaFrame = document.getElementById("conekta-checkout-element");
			this.conektaFrame.innerHTML = "";
			this.conektaShow = false;
		},
		showNuveiDialog() {
			this.nuveiDialog.showModal();
		},
		closeNuveiDialog() {
			this.nuveiDialog.close();
		},
		async getWebPayOptions() {
			this.busyText = this.languageStrings.loadingBuyIns;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/webpay/options`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.playerState.casinoId) params.set("casinoId", this.playerState.casinoId);

			params.set("casinoId", this.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.webPayOptions = dataJson;
				this.availableOffers = dataJson.availableOffers;
				this.inGameCurrencyInfo = dataJson.casino.currencyInfo;

				let _state = this.playerState;
				_state.restrictedNeedsMoreInfo =
					dataJson?.taxAmlRestrictions?.idCheckNeeded || dataJson?.taxAmlRestrictions?.morePersonalInfoNeeded;
				this.eventBus.emit("updatePlayerState", _state);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#buy-ins {
	display: grid;
	width: 95%;
	margin: auto;
	color: black;
}

#buy-ins {
	/* ****** temp fix for a CSS bug I need to understand ****** */
	top: 100px;
	left: 20px;
}

#webpay-container {
	display: none;
}

#webpay-checkout-element {
	width: 90%;
	margin: auto;
}

#webpay-container.show {
	display: grid;
	align-items: center;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	/* height: 100%; */
	width: 100%;
	background-color: #000;
}

#nuvei-dialog {
	width: 80%;
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
	box-sizing: border-box;
	text-align: center;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

#btn-container a {
	text-decoration: none;
}

#btn-container * {
	margin: 15px;
}

#conekta-checkout-container {
	display: none;
}

#conekta-checkout-container.show {
	display: grid;
	align-items: center;
	position: fixed;
	top: 115px;
	right: 0;
	bottom: 0;
	height: calc(100% - 115px);
	width: 100%;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
}

#conekta-checkout-element {
	height: 500px;
}

/* #webpay-checkout-element {
    padding: 30px;
} */

#main-nav-hidden {
	width: 100%;
	margin-left: 0;
	background-color: white;
	margin-top: 115px;
	height: 810px;
	overflow: hidden;
	color: black;
}

.nuvei-cancel-button {
	margin-top: 170px;
}

@media (min-width: 768px) {
	#buy-ins {
		width: 80%;
		margin-left: 20%;
		/* display: flex; */
		position: absolute;
		height: 810px;
		overflow: hidden;
	}

	#nuvei-dialog {
		width: 50%;
	}

	#webpay-container.show {
		top: 90px;
	}
}

@media (max-width: 767px) {
	#buy-ins {
		/* background-color: white; */
		width: 100%;
	}

	#main-nav-hidden {
		margin-top: 96px;
	}
}
</style>
